body {
  margin: 0;
}
.Navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 1;
}

.contentMain {
  position: relative;
  height: auto;
  min-height: 800px;
}

.headers {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.heightAuto {
  min-height: auto !important;
}

.notification {
  position: absolute;
  top: 10px;
  z-index: 99999;
}

@media (max-width: 420px) {
  .notification {
    right: 20px;
  }
}

@media (max-width: 810px) {
  .notification {
    right: 15px;
  }
}

@media (min-width: 811px) {
  .notification {
    right: 64px;
  }
}

.iamNavUnAuth {
  padding-bottom: 56px;
}

@media screen and (min-width: 810px) and (max-width: 1439px) {
  .iamNavAuth {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 1440px) {
  .iamNavAuth {
    padding-bottom: 121px;
  }
}

@media screen and (max-width: 420px) {
  .iamNavAuth {
    padding-bottom: 0px;
  }
}


