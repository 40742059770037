.tablehead {
  background-color: var(--weir-default-text);
}

.h1headerAlign {
  padding-top: 30px;
}

.textType {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--weir-default-text);
}

.paraAlign {
  margin: 20px 0px;
}

.h1header {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  color: var(--weir-header-dark-text);
}

.h2header {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: var(--weir-header-dark-text);
}

.h3header {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: var(--weir-header-dark-text);
}

.h4header {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--weir-default-text);
}

.anchorTagFont,
.anchorTagFont:focus {
  color: var(--weir-blue);
  text-decoration: none;
  font-size: 16px;
}

.anchorTagFont:hover {
  color: var(--weir-header-dark-text);
  text-decoration: none;
}

.tableCookies {
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background-color: transparent;
  box-sizing: border-box;
  display: table;
  color: var(--weir-grey);
}

.cookiesTable {
  border: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0px;
}

.cookiesTable > tbody > tr > td,
.cookiesTable > thead > tr > td {
  padding: 5px;
  border: 1px solid #dddddd;
}

.linkSpacing {
  margin-left: 4px;
  margin-right: 4px;
}

.paraAlign span + a, .paraAlign a + a {
  margin-left: 4px;
}

.paraAlign a + a {
  margin-left: 4px;
}
