#root {
  position: relative;
  --weir-blue: #005eb8;
  --weir-grey: #425563;
  --weir-orange: #ed8b00;
  --weir-purple: #702082;
  --weir-green: #78be20;
  --weir-lightblue: #00a9e0;
  --weir-red: #e04e39;
  --weir-yellow: #ffc72d;
  --weir-default-text: #72808a;
  --weir-status-green: #00b26e;
  --weir-header-dark-text: #2d343d;
  --weir-grey-border: #c6d0e0;
  --weir-login-text: #354859;
  --weir-input-label: #a0aab1;
  font-size: 16px;
}

.tab-content {
  max-height: 50vh;
  overflow: auto;
}

.bm-item-list {
  overflow: hidden;
}

.wselectError {
  margin-top: -10px !important;
}

html {
  height: 100%;
}

body {
  box-sizing: border-box;
  height: 100%;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  color: #425563;
}

h1 {
  font-weight: normal;
}

.btn-link {
  padding-top: 0px;
  color: black;
  font-weight: bold;
}

.centered {
  position: fixed;
  top: 35%;
  left: 50%;
}

.btn-link:hover {
  color: black;
}

.btn-link:visited {
  color: black;
}

.btn-link:focus {
  color: black;
}

.btn-link:active {
  text-decoration: none;

  color: black;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.contentMain {
  position: relative; /* needed for footer positioning*/
  height: auto; /* real browsers */
  min-height: 800px;
  padding-top: 1vw;
}

.navbar > .container {
  width: 100% !important;
}

.navbar-default .navbar-nav > li > a {
  text-align: center;
  color: #fff;
}

.navbar-default .navbar-nav > li > a:hover {
  background-color: transparent;
  color: #fff;
}

.navbar-default .navbar-nav > li > a:focus {
  background-color: transparent;
  color: #fff;
  border: none;
  box-shadow: none;
}

.navbar-default .navbar-nav > .hover > a {
  color: #fff;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: transparent;
  border: none;
}

.navbar-default .navbar-brand:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-default .navbar-nav > .active > a {
  color: #fff;
  background-color: transparent;
}

.navbar-default .navbar-nav > .focus > a {
  background-color: transparent;
  color: #fff;
  border: none;
  box-shadow: none;
}

.navbar-default .navbar-brand {
  color: #fff;
  font-size: 1.8vmax;
}

.navbar-header .nav-button {
  font-size: 1.3vw;
}

.navbar-header {
  display: inline-block;
}

.navbar-collapse {
  background-color: #005eb8;
}

.nav-button {
  padding: 0px;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: #005eb8;
}

#profileSettingdropdown {
  background-color: rgb(237, 139, 0);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-top: 12px;
  font-size: 15px;
  padding: 6px;
  margin-right: 16px;
  padding-left: 4px;
}

#profileSettingdropdown > span {
  display: none;
}

#apps-nav-dropdown > span {
  display: none;
}

#basic-nav-dropdown {
  line-height: 30px;
}

.home-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.home-button {
  border: none;
  outline: none;
  background: none;

  font-weight: lighter;
}

.home-button:hover {
  border: none;
  outline: none;
  background: none;
}

.home-button:visited {
  border: none;
  outline: none;
  background: none;
}

.home-button:focus {
  border: none;
  outline: none;
  background: none;
}

.home-button:active {
  border: none;
  outline: none;
  background: none;
}

.home-button:active:focus {
  border: none;
  outline: none;
  background: none;
}

.home-button-img {
  width: 100%;
  margin: 15px;
}

.panel-head-wrapper {
  display: flex;
  justify-content: space-between;
}

.glyphicon-refresh-animate {
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.timeFont {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5vw;
}

.green {
  background-color: rgba(114, 231, 114, 0.9);
}
.yellow {
  background-color: rgba(255, 187, 90, 0.9);
}
.red {
  background-color: rgba(255, 93, 88, 0.9);
}
.gray {
  background-color: rgba(133, 133, 133, 0.9);
}

.timeDisplay {
  padding-right: 5px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 2vmax;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.info-panel {
  display: flex;
  margin: 0.3em;
  border: 2px solid Black;
  font-size: 1em;
}
.id-box-left {
  display: flex;
  flex-basis: 12%;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.2em;
  text-align: left;
  border-right: 3px solid Black;
  font-size: 3em;
}
.id-box-right {
  display: flex;
  flex-basis: 8%;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.2em;
  text-align: right;
  border-right: 3px solid Black;
  font-family: 'Roboto', sans-serif;
  font-size: 3em;
}
.info-box {
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
}
.indicator {
  margin-top: 0.25em;
  margin-left: 0.2em;
  font-size: 0.75em;
}
.indicatorLarge {
  margin-top: 0.25em;
  font-size: 30px;
  margin-left: 0.2em;
  margin-right: 0.2em;
}
.info {
  margin-top: 0.5em;
  margin-right: 0.1em;
  margin-left: 0.1em;
}
.top-row {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 2em;
}
.middle-row {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 2em;
  border-top: 1px solid #425563;
}

.ghost-cell {
  color: white;
}
.bottom-row {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 2em;
  border-top: 1px solid #425563;
}

.nameSub {
  margin-left: 0.2em;
  font-size: 0.3em;
}
.textCaptions {
  margin-top: 0.2em;
  font-size: 1.1em;
}
.thirds {
  flex-basis: 33%;
}
.twentyFourths {
  flex-basis: 4.16%;
  padding-top: 0.32em;
  border-right: 1px solid #425563;
  font-size: 1em;
}
.halves {
  flex-basis: 50%;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.rbt {
  outline: none;
}
.rbt-menu {
  margin-bottom: 2px;
}
.rbt-menu > li a {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-menu > li a:focus {
  outline: none;
}
.rbt-menu-pagination-option {
  text-align: center;
}
.rbt .rbt-input-main::-ms-clear {
  display: none;
}
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  height: auto;
}
.rbt-input-multi.focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
  outline: 0;
}
.rbt-input-multi.form-control[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}
.rbt-input-multi input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.rbt-input-multi input:-ms-input-placeholder {
  color: #999;
}
.rbt-input-multi input::-webkit-input-placeholder {
  color: #999;
}
.rbt-input-multi .rbt-input-wrapper {
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}
.rbt-input-multi .rbt-input-main {
  height: 20px;
  margin: 1px 0 4px;
}
.rbt-input-multi .rbt-input-hint-container {
  display: inline-block;
}
.rbt-input-multi.input-lg .rbt-input-main,
.rbt-input-multi.form-control-lg .rbt-input-main {
  height: 24px;
}
.rbt-input-multi.input-sm .rbt-input-main,
.rbt-input-multi.form-control-sm .rbt-input-main {
  height: 18px;
}
.rbt-close {
  z-index: 1;
}
.rbt-close-lg {
  font-size: 24px;
}
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 2px;
  color: #1f8dd6;
  display: inline-block;
  line-height: 1em;
  margin: 0 3px 3px 0;
  padding: 4px 7px;
  position: relative;
}
.rbt-token-disabled {
  background-color: #ddd;
  color: #888;
  pointer-events: none;
}
.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}
.rbt-token-active {
  background-color: #1f8dd6;
  color: #fff;
  outline: none;
  text-decoration: none;
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}
.rbt-loader {
  -webkit-animation: loader-animation 600ms infinite linear;
  -o-animation: loader-animation 600ms infinite linear;
  animation: loader-animation 600ms infinite linear;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  border-top-color: #1f8dd6;
  display: block;
  height: 16px;
  width: 16px;
}
.rbt-loader-lg {
  height: 20px;
  width: 20px;
}
.rbt-aux {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}
.rbt-aux-lg {
  width: 46px;
}
.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}
.has-aux .rbt-input {
  padding-right: 34px;
}
.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/* Input Groups */
.input-group > .rbt {
  -webkit-flex: 1; /* Older versions of Safari */
  -ms-flex: 1;     /* IE 10 */
  flex: 1;         /* Modern browsers */
}
.input-group > .rbt .rbt-input-hint-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.input-group > .rbt .rbt-input-hint {
  z-index: 5;
}
.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

/* Controls the type-ahead on the search-documents page. */
.cnp__control {
  border-width: 0px 0px 0px 0px !important;
  border-radius: 0px !important;
}

.cnp__indicators {
  display: none !important;
}

.table > tbody > tr > td {
  padding: 0px;
  line-height: inherit;
  vertical-align: inherit;
  border-top: 1px solid #ddd;
}

.table > tbody > tr > th {
  padding: 0px;
  line-height: inherit;
  vertical-align: inherit;
}

@media (max-width: 350px) {
  .contentMain {
    position: relative; /* needed for footer positioning*/
    height: 600px !important; /* real browsers */
    min-height: 600px !important;
  }
}

@media (max-width: 576px) {
  .contentMain {
    position: relative; /* needed for footer positioning*/
    height: 650px !important; /* real browsers */
    min-height: 650px !important;
  }
}

@media screen and (max-width: 750px) and (min-width: 250px) {
  body {
    width: fit-content;
  }
}

:root {
    --weirGroupBlue: #425563;
    --weirOilAndGasBlue: #005eb8;
    --weirMineralsOrange: #ed8b00;
    --weirEscoRed: #c92c2b;
    --weirBlue: #005eb8;
    --weirBlueAccent: #003d8a;
    --danger: #fcedeb;
    --dangerHover: #fce1d9;
    --warning: #fffaea;
    --warningHover: #fff3bd;
    --success: #e5f8f1;
    --successHover: #cbf5e8;
    --onyx: #2d343d;
    --slate: #72808a;
    --slateDark: #72808c;
    --silver: #a0aab1;
    --stone: #d0d4d8;
    --dust: #eceeef;
    --snow: #fafbfc;
    --blueSlate: #2f4c6e;
    --blueSlateHighlight: #08162f;
    --blueSilver: #8fa8f0;
    --blueSilverHighlight: #506e90;
    --blueStone: #cdd8e3;
    --blueStoneHighlight: #a4b6ca;
    --weirBlue: #005eb8;
    --weirBlueHighlight: #003d8a;
    --ocean: #418fdf;
    --oceanHighlight: #1050c3;
    --sky: #9ac9f3;
    --skyHighlight: #5d9ee7;
    --red: #e04e39;
    --redHighlight: #c4170c;
    --yellow: #ffc72c;
    --yellowHighlight: #ff9b07;
    --green: #00b26e;
    --greenHighlight: #007c2e;
    --textureBackground: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23000000' fill-opacity='0.1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E);
   
    --whiteBackground: #ffffff;
    --white: #ffffff;
    --black: #000000;
    --skyLight: #f0f6fb;
    --greyBorder: #e5e7ea;
}

.sign-up-wrapper .dls-grid-container {
  width: auto !important;
}